"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WIDGET_HIDDEN = exports.USER_SELECTED_THREAD = exports.WIDGET_CLOSED = exports.WIDGET_LOADED = exports.QUICK_REPLY_BUTTON_CLICK = exports.USER_INTERACTED_WITH_WIDGET = exports.CONTACT_ASSOCIATED = exports.INPUT_STAGING = exports.UNREAD_CONVERSATION_COUNT_CHANGED = exports.CONVERSATION_CLOSED = exports.CONVERSATION_STARTED = void 0;
const CONVERSATION_STARTED = 'conversationStarted';
exports.CONVERSATION_STARTED = CONVERSATION_STARTED;
const CONVERSATION_CLOSED = 'conversationClosed';
exports.CONVERSATION_CLOSED = CONVERSATION_CLOSED;
const UNREAD_CONVERSATION_COUNT_CHANGED = 'unreadConversationCountChanged';
exports.UNREAD_CONVERSATION_COUNT_CHANGED = UNREAD_CONVERSATION_COUNT_CHANGED;
const INPUT_STAGING = 'inputStaging';
exports.INPUT_STAGING = INPUT_STAGING;
const CONTACT_ASSOCIATED = 'contactAssociated';
exports.CONTACT_ASSOCIATED = CONTACT_ASSOCIATED;
const USER_INTERACTED_WITH_WIDGET = 'userInteractedWithWidget';
exports.USER_INTERACTED_WITH_WIDGET = USER_INTERACTED_WITH_WIDGET;
const QUICK_REPLY_BUTTON_CLICK = 'quickReplyButtonClick';
exports.QUICK_REPLY_BUTTON_CLICK = QUICK_REPLY_BUTTON_CLICK;
const WIDGET_LOADED = 'widgetLoaded';
exports.WIDGET_LOADED = WIDGET_LOADED;
const WIDGET_CLOSED = 'widgetClosed';
exports.WIDGET_CLOSED = WIDGET_CLOSED;
const USER_SELECTED_THREAD = 'userSelectedThread';
exports.USER_SELECTED_THREAD = USER_SELECTED_THREAD;
const WIDGET_HIDDEN = 'widgetHidden';
exports.WIDGET_HIDDEN = WIDGET_HIDDEN;